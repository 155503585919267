<template>
  <b-overlay id="overlay-background" spinner-variant="primary" :show="isLoading" variant="transparent" opacity="1.00"
    blur="0.5em" rounded="sm" class="w-100">
    <b-row v-if="orderDetail">
      <b-col cols="12">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p class="m-0">Order Status:</p>
              <b-badge :variant="statusOrderVariant[orderDetail.status]">
                <span class="m-0" style="font-size: 1rem">{{
    statusOrder[orderDetail.status]
  }}</span>
              </b-badge>
            </div>
            <div>
              <a class="ml-1 btn btn-success" target="_blank" :href="`https://wa.me/${waNumberFormat(
    orderDetail.shipment.receiver_phone
  )}`" v-if="orderDetail !== null &&
    orderDetail.status === 'UNCONFIRM_CANCEL_ORDER'
    ">
                <i class="fa fa-whatsapp"></i>Hubungi
              </a>
              <b-button v-if="orderDetail.shipment.biteship_waybill_id" variant="primary" @click="doGetTrackingHistory">
                <b-spinner v-if="loadingShipmentSend" small class="mr-1"></b-spinner>
                <span>Tracking</span>
              </b-button>
              <b-button class="ml-1" v-if="orderDetail.status === 'UNCONFIRM'" variant="primary"
                @click="processModal = true">Konfirmasi/Proses</b-button>
              <b-button class="ml-1" v-if="orderDetail.status === 'ON_PROCESS'" :disabled="loadingShipmentSend"
                variant="primary" @click="showShipmentModal = true"><span>Kirim</span>
              </b-button>
              <b-button class="ml-1"
                v-if="(orderDetail.status === 'ON_DELIVERY' || orderDetail.status === 'DELIVERED') && user.is_super_admin === true"
                :disabled="loadingFinish" variant="success" @click="doFinishOrder"><span>Finish</span>
              </b-button>
              <router-link class="ml-1" v-if="orderDetail.is_refund" variant="primary" :to="{
    name: 'refunds_detail',
    params: { id: orderDetail.refund.id },
  }">
                Detail Refund
              </router-link>
              <b-button class="ml-1" v-if="orderDetail !== null &&
    (orderDetail.status === 'UNPAID' ||
      orderDetail.status === 'UNCONFIRM' ||
      orderDetail.status === 'ON_PROCESS' ||
      orderDetail.status === 'UNCONFIRM_CANCEL_ORDER')
    " variant="danger" @click="cancelOrderModal = true">
                Cancel Order
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card>
          <b-row>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">No. Invoice</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span class="font-weight-bolder">{{
    orderDetail.transaction.no_invoice
  }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Order Status</span>
            </b-col>
            <b-col :cols="orderDetail !== null && orderDetail.status === 'PENDING' ? 4 : 8
    " class="mb-1">
              <b-form-input v-model="orderDetail.status" disabled placeholder="Payment Status" />
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Pembayaran</span>
            </b-col>
            <b-col :cols="8" class="mb-1">
              <b-form-input v-model="orderDetail.transaction.status" disabled placeholder="Payment Status" />
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Tgl. Pembayaran</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <b-form-input v-model="orderDetail.transaction.payment_date" placeholder="Tanggal Pembayaran" disabled />
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Delivery</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <b-form-input v-model="orderDetail.shipment.biteship_shipping_status" placeholder="Delivery Status"
                disabled />
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Tgl. Delivery</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <b-form-input v-model="orderDetail.shipment.received_date" placeholder="Tanggal Delivery" disabled />
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Warehouse</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ orderDetail.warehouse.warehouse_name }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card>
          <b-row>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Nama Customer</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <router-link :to="{
    name: 'apps-users-view',
    params: { id: orderDetail.user_id },
  }">
                <span>{{ orderDetail.user.nama_customer }}</span>
              </router-link>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Email</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ orderDetail.user.email }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">No. Hp</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ orderDetail.user.no_hp }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title> Produk </b-card-title>
          <b-table responsive :items="orderDetail.items" :fields="itemsColumn">
            <template #cell(no)="data">
              <span>{{ data.index + 1 }}.</span>
            </template>
            <template #cell(kd_produk)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder">{{ data.item.name }}</span>
                <span style="font-size: smaller">{{
    data.item.kd_produk
  }}</span>
              </div>
            </template>
            <template #cell(harga)="data">
              {{ formatCurrency(data.item.original_price ? data.item.original_price : data.item.price) }}
            </template>
            <template #cell(total)="data">
              {{ formatCurrency(calcTotal(data.item)) }}
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card>
          <b-card-title> Voucher/Kupon </b-card-title>
          <div v-if="orderDetail.vouchers_usage.length > 0">
            <b-row v-for="voucher_usage in orderDetail.vouchers_usage" :key="voucher_usage.id" class="mb-2">
              <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                <span class="font-weight-bolder">Nomor Voucher</span>
              </b-col>
              <b-col cols="8" class="mb-1">
                <span>{{ voucher_usage.code }}</span>
              </b-col>
              <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                <span class="font-weight-bolder">Jenis</span>
              </b-col>
              <b-col cols="8" class="mb-1">
                <span>{{ typeVoucher(voucher_usage.type) }}</span>
              </b-col>
              <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                <span class="font-weight-bolder">Value</span>
              </b-col>
              <b-col cols="8" class="mb-1">
                <span>{{ formatCurrency(voucher_usage.value) }}
                  {{ voucher_usage.type === 'percentage' ? '%' : '' }}</span>
              </b-col>
            </b-row>
          </div>
          <div v-else class="w-100 d-flex justify-content-center">
            Tidak Ada Voucher
          </div>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card>
          <b-card-title> Pembayaran </b-card-title>
          <b-row>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Metode Bayar</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ title(orderDetail.transaction.type, '_') }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Kode Bank</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ title(orderDetail.transaction.bank_code, '_') }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Status</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ orderDetail.transaction.status }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Tanggal Pembayaran</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{
    orderDetail.transaction.payment_date === null
      ? '-'
      : orderDetail.transaction.payment_date
  }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card>
          <b-card-title> Alamat Pengiriman </b-card-title>
          <b-row>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Nama Penerima</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ orderDetail.shipment.receiver_name }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">No. HP/Telp</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ orderDetail.shipment.receiver_phone }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Label</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ orderDetail.shipment.type_address }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Alamat</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>
                {{ orderDetail.shipment.address }},
                {{ orderDetail.shipment.district }},
                {{ orderDetail.shipment.city }},
                {{ orderDetail.shipment.province }},
                {{ orderDetail.shipment.postal_code }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Catatan</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{
    orderDetail.shipment.note ? orderDetail.shipment.note : '-'
  }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card>
          <b-card-title> Status Pengiriman </b-card-title>
          <b-row>
            <b-col cols="4" class="text-right">
              <span class="font-weight-bolder">Courier Name</span>
            </b-col>
            <b-col cols="8">
              <span>{{ orderDetail.shipment.courier_name }}</span>
            </b-col>
            <b-col cols="4" class="text-right">
              <span class="font-weight-bolder">Courier Service</span>
            </b-col>
            <b-col cols="8">
              <span>{{ orderDetail.shipment.courier_service_name }}</span>
            </b-col>
          </b-row>
          <b-row v-if="orderDetail.shipment.biteship_waybill_id">
            <b-col cols="4" class="text-right">
              <span class="font-weight-bolder">Kode Resi</span>
            </b-col>
            <b-col cols="8">
              <span>{{ orderDetail.shipment.biteship_waybill_id }}</span>
            </b-col>
            <b-col cols="4" class="text-right">
              <span class="font-weight-bolder">Status</span>
            </b-col>
            <b-col cols="8">
              <span>{{ orderDetail.shipment.biteship_shipping_status }}</span>
            </b-col>
          </b-row>
          <div v-else class="text-center mt-3">
            <p>Belum melakukan Pengiriman</p>
            <b-button @click="waybillModal = true" variant="primary">Input Resi Manual</b-button>
          </div>
        </b-card>
      </b-col>
      <b-col cols="6" />
      <b-col cols="6">
        <b-card>
          <b-card-title> Order Total </b-card-title>
          <b-row>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Sub Total</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ formatCurrency(orderDetail.sub_total) }}</span>
            </b-col>
            <b-col v-if="orderDetail.vouchers_usage.find(
    el => el.type === 'fixed' || el.type === 'percentage'
  )
    " cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Discount</span>
            </b-col>
            <b-col v-if="orderDetail.vouchers_usage.find(
    el => el.type === 'fixed' || el.type === 'percentage'
  )
    " cols="8" class="mb-1">
              <span>{{
    orderDetail.vouchers_usage.find(
      el => el.type === 'fixed' || el.type === 'percentage'
    ).type === 'fixed'
      ? formatCurrency(
        orderDetail.vouchers_usage.find(
          el => el.type === 'fixed' || el.type === 'percentage'
        ).value
      )
      : `${orderDetail.vouchers_usage.find(
        el => el.type === 'fixed' || el.type === 'percentage'
      ).value
      }%`
  }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Biaya Pengiriman/Kurir</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ formatCurrency(orderDetail.shipment.price) }}</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Asuransi Pengiriman</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ formatCurrency(orderDetail.insurance_value) }}</span>
            </b-col>
            <b-col v-if="parseInt(orderDetail.reward_claimed) > 0" cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Penggunaan Poin</span>
            </b-col>
            <b-col v-if="parseInt(orderDetail.reward_claimed) > 0" cols="8" class="mb-1">
              <span>({{ formatCurrency(parseInt(orderDetail.reward_claimed)) }})</span>
            </b-col>
            <b-col v-if="orderDetail.vouchers_usage.find(el => el.type === 'shipping')
    " cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Gratis Ongkir</span>
            </b-col>
            <b-col v-if="orderDetail.vouchers_usage.find(el => el.type === 'shipping')
    " cols="8" class="mb-1">
              <span>({{
    formatCurrency(
      orderDetail.vouchers_usage.find(
        el => el.type === 'shipping'
      ).value
    )
  }})</span>
            </b-col>
            <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
              <span class="font-weight-bolder">Grand Total</span>
            </b-col>
            <b-col cols="8" class="mb-1">
              <span>{{ formatCurrency(orderDetail.total) }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <div v-else style="height: 100%" />
    <shipment-side-form :is-send-shipment-active.sync="showShipmentModal" :is-loading="loadingShipmentSend"
      @onSubmit="shipmentSubmit" />
    <b-modal v-model="waybillModal" centered title="Input Waybill" scrollable no-close-on-backdrop hide-footer>
      <b-card>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onUpdateWaybill)" @reset.prevent="resetForm">
            <!-- Full Name -->
            <validation-provider #default="validationContext" name="Waybill Number" rules="required">
              <b-form-group>
                <b-form-input id="note" v-model="waybill" autofocus :state="getValidationState(validationContext)" trim
                  placeholder="Waybill Number" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-button variant="primary" @click="handleSubmit(onUpdateWaybill)"><b-spinner small class="mr-1"
                v-if="waybillLoading"></b-spinner><span>Submit</span></b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </b-modal>
    <b-modal v-model="trackingModal" centered title="Tracking" hide-footer scrollable no-close-on-backdrop>
      <b-card>
        <app-timeline v-if="trackingHIstoryData && trackingHIstoryData.history.length > 0">
          <app-timeline-item v-for="history in trackingHIstoryData.history" :key="history.status">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">
                {{ title(history.status, '_') }}
              </h6>
              <small class="text-muted">{{
    formatDate(history.updated_at)
  }}</small>
            </div>
            <p>{{ history.note }}</p>
          </app-timeline-item>
        </app-timeline>
        <div v-else class="d-flex justify-content-center align-items-center">
          Tidak ada History
        </div>
      </b-card>
    </b-modal>
    <b-modal v-model="cancelOrderModal" centered title="Cancel Order" scrollable ok-title="Iya" cancel-title="Tidak"
      @ok="doCancelOrder" no-close-on-backdrop>
      <h5>Apakah anda yakin ingin membatalkan order ini?</h5>
      <b-form-checkbox v-if="orderDetail !== null && orderDetail.transaction.status === 'PAID'" id="refund-checkbox"
        v-model="cancelOrderForm.is_refund" name="is_refund" :value="true" :unchecked-value="false">
        Lakukan Refund
      </b-form-checkbox>
      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="cancelOrderLoading" @click="cancel">Tidak</b-button>
        <b-button :disabled="cancelOrderLoading" variant="primary" @click="ok"><b-spinner small class="mr-1"
            v-if="cancelOrderLoading"></b-spinner><span>Iya</span></b-button>
      </template>
    </b-modal>
    <b-modal v-model="refundModal" centered title="Refund" scrollable ok-title="Iya" cancel-title="Tidak" @ok="doRefund"
      no-close-on-backdrop>
      <h5>Apakah anda yakin ingin melakukan refund pada order ini?</h5>
      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="refundLoading" @click="cancel">Tidak</b-button>
        <b-button :disabled="refundLoading" variant="primary" @click="ok"><b-spinner small class="mr-1"
            v-if="refundLoading"></b-spinner><span>Iya</span></b-button>
      </template>
    </b-modal>
    <b-modal v-model="processModal" centered title="Process Order" scrollable ok-title="Iya" cancel-title="Tidak"
      @ok="doProcess" no-close-on-backdrop>
      <h5>Apakah anda yakin ingin memproses order ini?</h5>

      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="processLoading" @click="cancel">Tidak</b-button>
        <b-button :disabled="processLoading" variant="primary" @click="ok"><b-spinner small class="mr-1"
            v-if="processLoading"></b-spinner><span>Iya</span></b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BCardTitle,
  BOverlay,
  BButton,
  BSpinner,
  BFormCheckbox,
  BBadge,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref, watch } from '@vue/composition-api'
import { formatCurrency, title, formatDate } from '@/@core/utils/filter'
import { typeVoucherOptions } from '@core/utils/constant'
import useOrder from './useOrder'
import ShipmentSideForm from './components/ShipmentSideForm.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { statusOrder, statusOrderVariant } from '@core/utils/constant'
import service from '@/components/Table/service'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BCardTitle,
    BOverlay,
    ShipmentSideForm,
    BButton,
    BSpinner,
    AppTimeline,
    AppTimelineItem,
    BFormCheckbox,
    BBadge,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  setup(props, context) {
    const {
      retrieveOrder,
      orderDetail,
      calcTotal,
      subTotal,
      total,
      isLoading,
      shipmentSubmit,
      loadingShipmentSend,
      getTrackingHistory,
      trackingHIstoryData,
      trackingModal,
      cancelOrder,
      cancelOrderLoading,
      cancelOrderModal,
      refund,
      refundLoading,
      refundModal,
      process,
      processLoading,
      processModal,
      showShipmentModal,
      waybillLoading,
      waybillModal,
      updateWaybill,
      loadingFinish,
      finishOrder
    } = useOrder(context.root.$route.params.id)
    const itemsColumn = ref([
      {
        label: 'No.',
        key: 'no',
        thClass: 'number_column_class',
      },
      {
        label: 'Kode Produk',
        key: 'kd_produk',
      },
      {
        label: '@Harga',
        key: 'harga',
      },
      {
        label: 'Kuantiti',
        key: 'quantity',
      },
      {
        label: 'Diskon',
        key: 'discount',
        formatter: val => {
          if (val !== 0 || val !== null) {
            return `${val}%`
          }
          return '0%'
        },
      },
      {
        label: 'Total',
        key: 'total',
      },
    ])

    const trackingHistory = ref([
      {
        note: 'SHIPMENT RECEIVED BY JNE COUNTER OFFICER AT [JAKARTA]',
        updated_at: '2021-03-16T18:17:00+07:00',
        status: 'dropping_off',
      },
      {
        note: 'RECEIVED AT SORTING CENTER [JAKARTA]',
        updated_at: '2021-03-16T21:15:00+07:00',
        status: 'dropping_off',
      },
      {
        note: 'SHIPMENT FORWARDED TO DESTINATION [JAKARTA , HUB VETERAN BINTARO]',
        updated_at: '2021-03-16T23:12:00+07:00',
        status: 'dropping_off',
      },
      {
        note: 'RECEIVED AT INBOUND STATION [JAKARTA , HUB VETERAN BINTARO]',
        updated_at: '2021-03-16T23:43:00+07:00',
        status: 'dropping_off',
      },
      {
        note: 'WITH DELIVERY COURIER [JAKARTA , HUB VETERAN BINTARO]',
        updated_at: '2021-03-17T09:29:00+07:00',
        status: 'dropping_off',
      },
      {
        note: 'DELIVERED TO [ainul yakin | 17-03-2021 11:15 | JAKARTA ]',
        updated_at: '2021-03-17T11:15:00+07:00',
        status: 'delivered',
      },
    ])

    const user = JSON.parse(localStorage.getItem('userData'))
    // console.log(user)

    const typeVoucher = typeCode =>
      typeVoucherOptions.find(x => x.value === typeCode).text

    const doGetTrackingHistory = () => {
      getTrackingHistory(context.root.$route.params.id)
    }

    const cancelOrderForm = ref({
      is_refund: false,
      order_id: context.root.$route.params.id,
    })
    const doCancelOrder = evt => {
      evt.preventDefault()
      cancelOrder(cancelOrderForm.value)
    }

    const doRefund = evt => {
      evt.preventDefault()
      refund({ order_id: context.root.$route.params.id })
    }

    const doProcess = evt => {
      evt.preventDefault()
      process({ order_id: context.root.$route.params.id })
    }

    const waNumberFormat = number => {
      let result = number.slice(1)
      result = '62'.concat(result)
      return result
    }

    const waybill = ref(null)
    const onUpdateWaybill = () => {
      updateWaybill({ id: orderDetail.value.id, waybill: waybill.value })
    }

    const waybillResetData = () => {
      waybill.value = null
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(waybillResetData)

    const doFinishOrder = () => {
      finishOrder({ id: context.root.$route.params.id })
    }

    return {
      orderDetail,
      itemsColumn,
      formatCurrency,
      calcTotal,
      title,
      formatDate,
      subTotal,
      total,
      isLoading,
      showShipmentModal,
      loadingShipmentSend,
      shipmentSubmit,
      typeVoucher,
      trackingModal,
      trackingHistory,
      trackingHIstoryData,
      doGetTrackingHistory,
      cancelOrderModal,
      cancelOrderForm,
      doCancelOrder,
      cancelOrderLoading,
      refundLoading,
      refundModal,
      doRefund,
      statusOrder,
      statusOrderVariant,
      doProcess,
      processLoading,
      processModal,
      waNumberFormat,
      waybillModal,
      waybillLoading,
      onUpdateWaybill,
      waybill,
      refFormObserver,
      getValidationState,
      resetForm,
      user,
      loadingFinish,
      doFinishOrder
    }
  },
}
</script>
<style lang="scss">
.number_column_class {
  width: 50px;
}
</style>
